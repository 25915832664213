<template>
    <div class="fun-facts-area pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-fun-fact-box">
                        <div class="icon">
                            <i class="ri-user-line"></i>
                        </div>
                        <h3>3230+</h3>
                        <p>Total Cases</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-fun-fact-box">
                        <div class="icon bg-FF414B">
                            <i class="ri-briefcase-line"></i>
                        </div>
                        <h3>3026+</h3>
                        <p>Case Solved</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-fun-fact-box">
                        <div class="icon bg-36CC72">
                            <i class="ri-trophy-line"></i>
                        </div>
                        <h3>320+</h3>
                        <p>Winning Awards</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-fun-fact-box">
                        <div class="icon bg-FFCA40">
                            <i class="ri-flag-line"></i>
                        </div>
                        <h3>102+</h3>
                        <p>Country Over</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FunFacts'
}
</script>