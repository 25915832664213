<template>
  <div class="preloader-area">
    <div class="spinner">
      <div class="inner">
        <div class="disc"></div>
        <div class="disc"></div>
        <div class="disc"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
};
</script>