<template>
    <div class="case-details-area ptb-100">
        <div class="container">
            <div class="case-details-image">
                <img src="../../assets/images/cases-details/cases-details-1.jpg" alt="image">
            </div>

            <div class="row">
                <div class="col-lg-9 col-md-12">
                    <div class="case-details-content">
                        <h3>Design and Development for IT Startups</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus elit bibendum adipiscing tincidunt mattis orci consequat egestas mattis. Convallis massa feugiat sapien urna egestas elit vitae, ipsum. In pulvinar urna, viverra quam habitasse blandit eget. Quis semper consectetur sed a purus id ac, auctor. Sem sed eleifend eu iaculis enim.</p>
                        <p>Urna tellus, duis nec consectetur tempor venenatis risus volutpat. Sit sem ornare eget auctor amet vitae. Eu nulla facilisi posuere tellus massa dignissim. Ut amet viverra non amet enim at venenatis viverra mauris. Malesuada</p>
                    </div>

                    <div class="case-details-content-two">
                        <h3>Project Description</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus elit bibendum adipiscing tincidunt mattis orci consequat egestas mattis. Convallis massa feugiat sapien urna egestas elit vitae, ipsum. In pulvinar urna, viverra quam habitasse blandit eget. Quis semper consectetur sed a purus id ac, auctor. Sem sed eleifend eu iaculis enim.</p>
                        <p>Urna tellus, duis nec consectetur tempor venenatis risus volutpat. Sit sem ornare eget auctor amet vitae. Eu nulla facilisi posuere tellus massa dignissim. Ut amet viverra non amet enim at venenatis viverra mauris. Malesuada Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus elit bibendum adipiscing tincidunt mattis orci consequat egestas mattis. Convallis massa feugiat sapien urna egestas elit vitae, ipsum. In pulvinar urna, viverra quam habitasse blandit eget. Quis semper consectetur sed a purus id ac, auctor. Sem sed eleifend eu iaculis enim.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus elit bibendum adipiscing tincidunt mattis orci consequat egestas mattis. Convallis massa feugiat sapien urna egestas elit vitae, ipsum. In pulvinar urna,</p>
                    </div>

                    <div class="case-details-overview-image">
                        <img src="../../assets/images/cases-details/cases-details-2.jpg" alt="image">
                    </div>

                    <div class="case-details-overview-image">
                        <img src="../../assets/images/cases-details/cases-details-3.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-3 col-md-12">
                    <div class="case-details-information">
                        <ul class="information-list">
                            <li>
                                <span>Share On :</span>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                                <a href="https://www.twitter.com/" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                                <a href="https://www.linkedin.com/" target="_blank">
                                    <i class="ri-linkedin-fill"></i>
                                </a>
                                <a href="https://www.messenger.com/" target="_blank">
                                    <i class="ri-messenger-fill"></i>
                                </a>
                            </li>

                            <li>
                                <span>Category :</span> Finance & Management
                            </li>

                            <li>
                                <span>Client :</span> <a href="#" target="_blank">ThemeForest.com</a>
                            </li>

                            <li>
                                <span>Website :</span> <a href="https://envytheme.com/" target="_blank">EnvyTheme.com</a>
                            </li>

                            <li>
                                <span>Date :</span> 24 March 2022
                            </li>
                        </ul>

                        <div class="case-contact-info">
                            <h3>Let's Make Something Amazing Together!</h3>

                            <div class="info-box-one">
                                <i class="ri-customer-service-line"></i>
                                <a href="tel:3128959800">(312) 895-9800</a>
                            </div>

                            <div class="info-box-two">
                                <i class="ri-earth-line"></i>
                                <a href="mailto:hello.me@plob.com">hello.me@plob.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CaseStudyDetails'
}
</script>