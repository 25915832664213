<template>
    <div class="analysis-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="analysis-image">
                        <img src="../../assets/images/analysis/analysis.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="analysis-item">
                        <div class="analysis-content">
                            <h3>Over 12 Year Professional Experiences</h3>
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit  auctor. nam elit nulla eget sodales dui pulvinar. Best Strategic planning dolor sit sectetur morethe</p>
                            <p>Scelerisque amet odio velit eu auctor. Aliquet nam elit.</p>
                        </div>
                        <div class="analysis-inner-content">
                            <img src="../../assets/images/analysis/img1.png" alt="image">
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. risque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar. Best eone Strategic planning dolor.</p>
                        </div>
                        <div class="analysis-inner-content">
                            <img src="../../assets/images/analysis/img2.png" alt="image">
                            <p>Plob digital agency for your start  planning dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit nulla</p>
                        </div>
                        <div class="analysis-inner-content">
                            <img src="../../assets/images/analysis/img3.png" alt="image">
                            <p>Best Strategic planning dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit nulla.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="analysis-shape-1">
            <img src="../../assets/images/analysis/shape-1.png" alt="image">
        </div>
        <div class="analysis-shape-2">
            <img src="../../assets/images/analysis/shape-2.png" alt="image">
        </div>
        <div class="analysis-shape-3">
            <img src="../../assets/images/analysis/shape-3.png" alt="image">
        </div>
        <div class="analysis-shape-4">
            <img src="../../assets/images/analysis/shape-4.png" alt="image">
        </div>
        <div class="analysis-shape-5">
            <img src="../../assets/images/analysis/shape-5.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfessionalExperiences'
}
</script>