<template>
    <section class="support-area">
        <div class="container z-2 position-relative">
            <div class="row justify-content-between align-items-center">
                <div class="col-md-6 col-lg-4">
                    <div class="section-title text-start">
                        <h2 class="text-white">Stay One Step Ahead of Fraud</h2>
                        <p class="mb-3 text-white">
                            Fraud prevention is one of our top priorities. We partner with Evina, the industry standard
                            in fraud detection and prevention.
                        </p>
                        <p class="text-white mb-3">
                            When layered on top of clean and direct acquisition sources, we can all sleep more soundly
                            knowing that we are fraud-free.
                        </p>
                        <div>
                            <a href="https://www.evina.com/resources/case-study-digital-distribution-group-x-evina/" class="default-btn" target="_blank">
                                Case study
                                <i class="ri-file-list-line"></i>
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <img src="../../assets/images/evina-logo.png" />
                </div>
            </div>
            <!--      <div class="support-content">-->
            <!--        <div class="tag">-->
            <!--          <img src="../../assets/images/experiences/tag-icon.png" alt="image" />-->
            <!--        </div>-->

            <!--        <h3>Lightning-Fast Tech Support, Guaranteed</h3>-->
            <!--        <p>-->
            <!--          As a midsize software development company, we combine the best of both-->
            <!--          worlds. We have the focus and speed of the small IT outsourcing-->
            <!--          companies along with the scalability and expertise of the big ones.-->
            <!--        </p>-->
            <!--        <span>Regan Rosen, CEO at Flod Agency</span>-->
            <!--      </div>-->
        </div>

        <div class="support-shape-1">
            <img src="../../assets/images/support/shape-1.png" alt="image" />
        </div>
        <div class="support-shape-2">
            <img src="../../assets/images/support/shape-2.png" alt="image" />
        </div>
        <div class="support-shape-3">
            <img src="../../assets/images/support/shape-3.png" alt="image" />
        </div>
        <div class="support-shape-4">
            <img src="../../assets/images/support/shape-4.png" alt="image" />
        </div>
        <div class="support-shape-5">
            <img src="../../assets/images/support/shape-5.png" alt="image" />
        </div>
        <div class="support-shape-6">
            <img src="../../assets/images/support/shape-6.png" alt="image" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'TechSupport',
};
</script>
