<template>
    <secion
        class="service-case-study-area jarallax"
        ref="jarallax"
        data-jarallax-video="m4v:../../video/m4v-ddg-comp/01.m4v,webm:../../video/01.webm,ogv:../../video/01.ogv"
    >
        <div class="service-bg-layover" :class="serviceName"></div>
        <slot name="service-case-study-content"></slot>
    </secion>
</template>
<script>
import {jarallax, jarallaxVideo} from "jarallax";

export default {
    name: 'ServicesCaseStudy',
    components: {},
    props: ['serviceName', 'videoName'],
    mounted() {
        jarallaxVideo();
        jarallax(this.$refs.jarallax, {
            speed: 0.2,
            videoSrc: `webm:../../video/${this.videoName}.webm`,
        });
    },
};
</script>
