<template>
    <div class="services-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-12">
                    <div class="services-details-information">
                        <ul class="services-list">
                            <li><router-link to="/services-one">Brand Identity Design</router-link></li>
                            <li><router-link to="/services-one">Digital Marketing</router-link></li>
                            <li><router-link to="/services-one" class="active">Design and Development</router-link></li>
                            <li><router-link to="/services-one">IT anad Startup Consulting</router-link></li>
                            <li><router-link to="/services-one">Cloud Computing Service</router-link></li>
                            <li><router-link to="/services-one">Domain and Hosting</router-link></li>
                        </ul>

                        <div class="services-contact-info">
                            <h3>Let's Make Something Amazing Together!</h3>

                            <div class="info-box-one">
                                <i class="ri-customer-service-line"></i>
                                <a href="tel:3128959800">(312) 895-9800</a>
                            </div>

                            <div class="info-box-two">
                                <i class="ri-earth-line"></i>
                                <a href="mailto:hello.me@plob.com">hello.me@plob.com</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-md-12">
                    <div class="services-details-overview-content">
                        <h3>Web Design and Development</h3>
                        <p>Web Design and Development, consectetur adipiscing elit. Cursus molestie sit lectus eu vel platea tellus amet quam. Metus, ultrices velit quis nisi varius. Parturient gravida odio felis sit at arcu habitant tristique. Malesuada ac mi elit, scelerisque felis eu pharetra. Maecenas eget lorem nisl lectus arcu ac commodo arcu elit. Tempus condimentum aenean neque id vulputate adipiscing facilisis etiam.</p>
                        <p>Proin erat viverra ac ac tellus. Odio porttitor blandit vitae, sagittis turpis ut diam lobortis lacus. Mauris ipsum sed ornare varius. Dui, orci sed rutrum fames metus at sit. A neque at eu eget vestibulum tortor. Volutpat imperdiet sit nulla phasellus morbi convallis id pretium volutpat. Sit amet urna pharetra, pharetra, elementum. Consectetur diam dignissim vestibulum lorem at. Eget facilisis ut elementum pharetra. Nibh dictum fermentum id suscipit porta orci a amet et. Non nunc, suspendisse amet amet ut odio est tristique nec. Neque, pretium elementum at tellus aliquam. Integer eu rhoncus nulla lectus nibh felis pretium ante. Nam enim sit sed accumsan proin tellus nisi adipiscing quam.</p>
                        <p>Nunc, mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis nunc nisi, ut diam proin faucibus. Hendrerit viverra in congue enim feugiat lacus amet sed. Ipsum sed magnis tortor, vel. Mauris mi eget ac morbi. Enim conseuat viverra odio commodo vitae most designer and developer.</p>

                        <ul class="overview-list">
                            <li><i class="ri-check-line"></i> Nunc, mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis</li>
                            <li><i class="ri-check-line"></i> Most of the designer are very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis</li>
                            <li><i class="ri-check-line"></i> There are two thing is very important in Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis</li>
                            <li><i class="ri-check-line"></i> Web design and development very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet to all design and development.</li>
                        </ul>

                        <div class="overview-image">
                            <img src="../../assets/images/services-details/services-details-1.jpg" alt="image">

                            <div class="shape-1">
                                <img src="../../assets/images/services-details/shape-1.png" alt="image">
                            </div>
                            <div class="shape-2">
                                <img src="../../assets/images/services-details/shape-2.png" alt="image">
                            </div>
                            <div class="shape-3">
                                <img src="../../assets/images/services-details/shape-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>