<template>
  <div class="overview-area pb-100">
    <div class="container">
      <div class="overview-box">
        <div class="overview-content">
          <h3>Let's Make Something Amazing Together</h3>

          <div class="overview-btn">
            <router-link to="/contact" class="overview-btn-one"
              >Get Started</router-link
            >
            <img src="../../assets/images/overview/bar.png" alt="image" />
          </div>
        </div>
      </div>
    </div>

    <div class="overview-shape-1">
      <img src="../../assets/images/overview/shape-1.png" alt="image" />
    </div>
    <div class="overview-shape-2">
      <img src="../../assets/images/overview/shape-2.png" alt="image" />
    </div>
    <div class="overview-shape-3">
      <img src="../../assets/images/overview/shape-3.png" alt="image" />
    </div>
    <div class="overview-shape-4">
      <img src="../../assets/images/overview/shape-4.png" alt="image" />
    </div>
    <div class="overview-shape-5">
      <img src="../../assets/images/overview/shape-5.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OverView",
};
</script>