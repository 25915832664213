<template>
    <section class="partner-area pb-100 pt-100">
        <div class="container">
            <div class="section-title">
                <h2>Our Partners</h2>
            </div>
            <div class="partner-slides">
                <div class="row partner-container">
                    <div class="col-md-3 col-sm-6 partner-item">
                        <div class="partner-logo">
                            <img src="../../assets/images/operators/orange-operator.png" alt="" />
                        </div>
                        <div class="partner-title">ORANGE GROUP AFRICA</div>
                    </div>
                    <div class="col-md-3 col-sm-6 partner-item">
                        <div class="partner-logo">
                            <img src="../../assets/images/operators/zain-operator.png" alt="" />
                        </div>
                        <div class="partner-title">ZAIN GROUP MIDDLE EAST</div>
                    </div>
                    <div class="col-md-3 col-sm-6 partner-item">
                        <div class="partner-logo">
                            <img src="../../assets/images/operators/telenor-operator.png" alt="" />
                        </div>
                        <div class="partner-title">TELENOR GROUP EUROPE</div>
                    </div>
                    <div class="col-md-3 col-sm-6 partner-item">
                        <div class="partner-logo">
                            <img src="../../assets/images/operators/verizon-operator.png" alt="" />
                        </div>
                        <div class="partner-title">VERIZON</div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'PartnerLogo',
});
</script>
