<template>
  <div class="faq-area ptb-100">
    <div class="container">
      <div class="faq-accordion accordion-box">
        <AccordionSlot>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                <i class="ri-add-line"></i>
                Why Are Consultants Important?
              </button>
            </template>
            <template v-slot:accordion-content>
              <div class="accordion-body">
                <p>
                  The IT industry offers a sea of options, from platforms,
                  programming languages, methodologies, technologies, tools, and
                  more. IT consulting services are important because they play a
                  vital role in businesses by covering the management,
                  implementation, deployment, and maintenance of an IT
                  infrastructure. IT industry offers a sea of options, from
                  platforms, programming languages, methodologies, technologies,
                  tools, and more.
                </p>
              </div>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                <i class="ri-add-line"></i>
                What is The Purpose of a Consultant?
              </button>
            </template>
            <template v-slot:accordion-content>
              <div class="accordion-body">
                <p>
                  The IT industry offers a sea of options, from platforms,
                  programming languages, methodologies, technologies, tools, and
                  more. IT consulting services are important because they play a
                  vital role in businesses by covering the management,
                  implementation, deployment, and maintenance of an IT
                  infrastructure. IT industry offers a sea of options, from
                  platforms, programming languages, methodologies, technologies,
                  tools, and more.
                </p>
              </div>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                <i class="ri-add-line"></i>
                What Attracts You To The Role of a Consultant?
              </button>
            </template>
            <template v-slot:accordion-content>
              <div class="accordion-body">
                <p>
                  The IT industry offers a sea of options, from platforms,
                  programming languages, methodologies, technologies, tools, and
                  more. IT consulting services are important because they play a
                  vital role in businesses by covering the management,
                  implementation, deployment, and maintenance of an IT
                  infrastructure. IT industry offers a sea of options, from
                  platforms, programming languages, methodologies, technologies,
                  tools, and more.
                </p>
              </div>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                <i class="ri-add-line"></i>
                What Are The Advantages of Being a Consultant?
              </button>
            </template>
            <template v-slot:accordion-content>
              <div class="accordion-body">
                <p>
                  The IT industry offers a sea of options, from platforms,
                  programming languages, methodologies, technologies, tools, and
                  more. IT consulting services are important because they play a
                  vital role in businesses by covering the management,
                  implementation, deployment, and maintenance of an IT
                  infrastructure. IT industry offers a sea of options, from
                  platforms, programming languages, methodologies, technologies,
                  tools, and more.
                </p>
              </div>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                <i class="ri-add-line"></i>
                Is Consulting a Good Career?
              </button>
            </template>
            <template v-slot:accordion-content>
              <div class="accordion-body">
                <p>
                  The IT industry offers a sea of options, from platforms,
                  programming languages, methodologies, technologies, tools, and
                  more. IT consulting services are important because they play a
                  vital role in businesses by covering the management,
                  implementation, deployment, and maintenance of an IT
                  infrastructure. IT industry offers a sea of options, from
                  platforms, programming languages, methodologies, technologies,
                  tools, and more.
                </p>
              </div>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                <i class="ri-add-line"></i>
                How is Working in Consulting?
              </button>
            </template>
            <template v-slot:accordion-content>
              <div class="accordion-body">
                <p>
                  The IT industry offers a sea of options, from platforms,
                  programming languages, methodologies, technologies, tools, and
                  more. IT consulting services are important because they play a
                  vital role in businesses by covering the management,
                  implementation, deployment, and maintenance of an IT
                  infrastructure. IT industry offers a sea of options, from
                  platforms, programming languages, methodologies, technologies,
                  tools, and more.
                </p>
              </div>
            </template>
          </accordion-item>
        </AccordionSlot>
      </div>

      <div class="ticket-box">
        <h3>Have Any Question in Mind Please Call or Mail Us</h3>

        <div class="box-one">
          <i class="ri-customer-service-line"></i>
          <a href="tel:3128959800">(312) 895-9800</a>
        </div>
        <div class="box-two">
          <i class="ri-earth-line"></i>
          <a href="mailto:hello.me@plob.com">hello.me@plob.com</a>
        </div>
      </div>
    </div>

    <div class="faq-shape-1">
      <img src="../../assets/images/faq/shape-1.png" alt="image" />
    </div>
    <div class="faq-shape-2">
      <img src="../../assets/images/faq/shape-2.png" alt="image" />
    </div>
    <div class="faq-shape-3">
      <img src="../../assets/images/faq/shape-3.png" alt="image" />
    </div>
    <div class="faq-shape-4">
      <img src="../../assets/images/faq/shape-4.png" alt="image" />
    </div>
    <div class="faq-shape-5">
      <img src="../../assets/images/faq/shape-5.png" alt="image" />
    </div>
  </div>
</template>

<script>
import AccordionSlot from "../Common/AccordionSlot";
import AccordionItem from "../Common/AccordionItem";

export default {
  name: "FaqAccordion",
  components: {
    AccordionSlot,
    AccordionItem,
  },
};
</script>