<template>
    <header class="main-header-area">
        <div class="top-header-area">&nbsp;</div>
        <MainNavbar class="navbar-box-style" />
    </header>
</template>

<script>
import MainNavbar from '../Layout/MainNavbar';

export default {
    name: 'MainHeader',
    components: {
        MainNavbar,
    },
};
</script>
