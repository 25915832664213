<template>
    <div class="main-banner-box-area">
        <div class="container">
            <div class="main-banner-inner-box">
                <div class="main-banner-box-content">
                    <div class="tag wow fadeInUp popup-youtube">
                        <img src="../../assets/images/main-banner/banner-three/tag-icon.png" alt="image">
                        # Plob Best Startup Company.
                    </div>
                    <h1 class="wow fadeInDown">Plob, Best IT & Technology Consulting Service in Your Area</h1>
                </div>

                <div class="main-banner-box-image">
                    <img src="../../assets/images/main-banner/banner-three/main-pic.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>