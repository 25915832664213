<template>
  <div class="team-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-1.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Oliver Watson</h3>
              <span>Senior IT Consultant</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-2.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Leah Chatman</h3>
              <span>Financial Consultant</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-3.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Louis Agassiz</h3>
              <span>Web Developer</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-4.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Carl Anderson</h3>
              <span>Web Designer</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-5.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Jems Rodrigez</h3>
              <span>UI UX Designer</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-6.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Emma Kemeliy</h3>
              <span>Digital Marketer</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-7.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Deren Bravoo</h3>
              <span>Business Developer</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-8.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Leah Sofiya</h3>
              <span>Financial Consultant</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
};
</script>