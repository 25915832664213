<template>
  <div class="team-area pb-100">
    <div class="container">
      <div class="section-title">
        <h2>Meet With Our Consultants</h2>
        <p>
          Which peoples loves us a lot, please check out what about says about
          us
        </p>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-1.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Oliver Watson</h3>
              <span>Senior IT Consultant</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-2.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Leah Chatman</h3>
              <span>Financial Consultant</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-3.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Louis Agassiz</h3>
              <span>Web Developer</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-team-box">
            <div class="image">
              <img src="../../assets/images/team/team-4.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank">
                    <i class="ri-messenger-fill"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="content">
              <h3>Carl Anderson</h3>
              <span>Web Designer</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="see-team-member-btn">
      <router-link to="/team-member" class="see-btn"
        >See Team Member</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
};
</script>