<template>
  <div class="about-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-image">
            <img src="../../assets/images/about/about.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="about-item">
            <div class="about-content">
              <div class="tag">
                <img src="../../assets/images/about/tag-icon.png" alt="image" />
              </div>
              <h3>Over 12 Year Professional Experiences</h3>
              <p>
                Best Strategic planning dolor sit amet consectetur adipiscing
                elit. Scelerisque amet odio velit auctor. nam elit nulla eget
                sodales dui pulvinar. Best Strategic planning dolor sit sectetur
                morethe
              </p>
              <p>Scelerisque amet odio velit eu auctor. Aliquet nam elit.</p>
            </div>

            <div class="about-inner-content">
              <img src="../../assets/images/about/img1.png" alt="image" />
              <p>
                Best Strategic planning dolor sit amet consectetur adipiscing
                elit. risque amet odio velit eu auctor. Aliquet nam elit nulla
                eget sodales dui pulvinar. Best eone Strategic planning dolor.
              </p>
            </div>

            <div class="about-inner-content">
              <img src="../../assets/images/about/img2.png" alt="image" />
              <p>
                Plob digital agency for your start planning dolor sit amet
                consectetur adipiscing elit. Scelerisque amet odio velit eu
                auctor. Aliquet nam elit nulla eget sodales dui pulvinar. Best
                Strategic planning.
              </p>
            </div>

            <div class="about-inner-content">
              <img src="../../assets/images/about/img3.png" alt="image" />
              <p>
                Best Strategic planning dolor sit amet consectetur adipiscing
                elit. risque amet odio velit eu auctor. Aliquet nam elit nulla
                eget sodales dui pulvinar. Best eone Strategic planning dolor.
              </p>
            </div>

            <div class="about-btn">
              <router-link to="/about-one" class="default-btn">
                Read More
                <i class="ri-arrow-right-line"></i>
                <span></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-shape-1">
      <img src="../../assets/images/about/shape-1.png" alt="image" />
    </div>
    <div class="about-shape-2">
      <img src="../../assets/images/about/shape-2.png" alt="image" />
    </div>
    <div class="about-shape-3">
      <img src="../../assets/images/about/shape-3.png" alt="image" />
    </div>
    <div class="about-shape-4">
      <img src="../../assets/images/about/shape-4.png" alt="image" />
    </div>
    <div class="about-shape-5">
      <img src="../../assets/images/about/shape-5.png" alt="image" />
    </div>
    <div class="about-shape-6">
      <img src="../../assets/images/about/shape-4.png" alt="image" />
    </div>
    <div class="about-shape-7">
      <img src="../../assets/images/about/shape-5.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfessionalExperiences",
};
</script>