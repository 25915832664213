<template>
    <section class="world-map-area ptb-100 position-relative">
        <div class="world-map-card-container">
            <div class="container h-100">
                <div class="world-map-card h-100 align-items-end" :class="{'is-left':isLeft}">
                    <div class="world-map-card-proximity" @mouseenter="isLeft = !isLeft">
                        <div class="section-title text-start mb-2 to-left">
                            <h2 class="text-white">
                                Trusted on a <br />
                                Global Scale
                            </h2>
                            <p class="text-white">
                                5 Continents <br />
                                37 Countries <br />
                                52 Carrier Partnerships
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vuevectormap width="100%" height="100%" :options="{}"></vuevectormap>
    </section>
</template>

<script>
export default {
    name: 'WorldMap',
    data() {
        return {
            isLeft: true
        }
    }
};

</script>
