<template>
    <div class="main-banner-with-large-shape-area without-banner-animation">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-white-content">
                        <div class="tag wow fadeInLeft">
                            <img src="../../assets/images/main-banner/banner-two/tag-icon.png" alt="image">
                            # Plob Best Startup Company.
                        </div>
                        <h1>Plob, Best IT Startup Consulting Company.</h1>
                        <p>Plob is a digital marketing agency sit amet consectetur adipiscing elit. Odio at ut tincidunt porttitor molestie aliquet quam cursus. Rhoncus donec libero et volutpat erat posuere sagittis cursus posuere.</p>

                        <div class="banner-btn">
                            <router-link to="/about-one" class="default-btn">
                                Read More 
                                <i class="ri-arrow-right-line"></i>
                                <span></span>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image-wrap">
                        <img src="../../assets/images/main-banner/banner-two/main-pic.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="main-banner-shape-5">
            <img src="../../assets/images/main-banner/banner-two/shape-1.png" alt="image">
        </div>
        <div class="main-banner-shape-6">
            <img src="../../assets/images/main-banner/banner-two/shape-2.png" alt="image">
        </div>
        <div class="main-banner-shape-7">
            <img src="../../assets/images/main-banner/banner-two/shape-3.png" alt="image">
        </div>
        <div class="main-banner-shape-8">
            <img src="../../assets/images/main-banner/banner-two/shape-4.png" alt="image">
        </div>
        <div class="main-banner-large-shape">
            <img src="../../assets/images/main-banner/banner-two/large-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>