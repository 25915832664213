<template>
  <div class="faq-area ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>Frequently Asked Questions</h2>
        <p>
          Which peoples loves us a lot, please check out what about says about
          us
        </p>
      </div>

      <div class="row align-items-center">
        <div class="col-lg-4 col-md-12">
          <div class="faq-image">
            <img src="../../assets/images/faq/faq-1.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-8 col-md-12">
          <div class="faq-accordion">
            <AccordionSlot>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title">
                    <i class="ri-add-line"></i>
                    Why Are Consultants Important?
                  </button>
                </template>
                <template v-slot:accordion-content>
                  <div class="accordion-body">
                    <p>
                      The IT industry offers a sea of options, from platforms,
                      programming languages, methodologies, technologies, tools,
                      and more. IT consulting services are important because
                      they play a vital role in businesses by covering the
                      management, implementation, deployment, and maintenance of
                      an IT infrastructure. IT industry offers a sea of options,
                      from platforms, programming languages, methodologies,
                      technologies, tools, and more.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title">
                    <i class="ri-add-line"></i>
                    What is The Purpose of a Consultant?
                  </button>
                </template>
                <template v-slot:accordion-content>
                  <div class="accordion-body">
                    <p>
                      The IT industry offers a sea of options, from platforms,
                      programming languages, methodologies, technologies, tools,
                      and more. IT consulting services are important because
                      they play a vital role in businesses by covering the
                      management, implementation, deployment, and maintenance of
                      an IT infrastructure. IT industry offers a sea of options,
                      from platforms, programming languages, methodologies,
                      technologies, tools, and more.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title">
                    <i class="ri-add-line"></i>
                    What Attracts You To The Role of a Consultant?
                  </button>
                </template>
                <template v-slot:accordion-content>
                  <div class="accordion-body">
                    <p>
                      The IT industry offers a sea of options, from platforms,
                      programming languages, methodologies, technologies, tools,
                      and more. IT consulting services are important because
                      they play a vital role in businesses by covering the
                      management, implementation, deployment, and maintenance of
                      an IT infrastructure. IT industry offers a sea of options,
                      from platforms, programming languages, methodologies,
                      technologies, tools, and more.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title">
                    <i class="ri-add-line"></i>
                    What Are The Advantages of Being a Consultant?
                  </button>
                </template>
                <template v-slot:accordion-content>
                  <div class="accordion-body">
                    <p>
                      The IT industry offers a sea of options, from platforms,
                      programming languages, methodologies, technologies, tools,
                      and more. IT consulting services are important because
                      they play a vital role in businesses by covering the
                      management, implementation, deployment, and maintenance of
                      an IT infrastructure. IT industry offers a sea of options,
                      from platforms, programming languages, methodologies,
                      technologies, tools, and more.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title">
                    <i class="ri-add-line"></i>
                    Is Consulting a Good Career?
                  </button>
                </template>
                <template v-slot:accordion-content>
                  <div class="accordion-body">
                    <p>
                      The IT industry offers a sea of options, from platforms,
                      programming languages, methodologies, technologies, tools,
                      and more. IT consulting services are important because
                      they play a vital role in businesses by covering the
                      management, implementation, deployment, and maintenance of
                      an IT infrastructure. IT industry offers a sea of options,
                      from platforms, programming languages, methodologies,
                      technologies, tools, and more.
                    </p>
                  </div>
                </template>
              </accordion-item>
              <accordion-item>
                <template v-slot:accordion-trigger>
                  <button class="accordion-title">
                    <i class="ri-add-line"></i>
                    How is Working in Consulting?
                  </button>
                </template>
                <template v-slot:accordion-content>
                  <div class="accordion-body">
                    <p>
                      The IT industry offers a sea of options, from platforms,
                      programming languages, methodologies, technologies, tools,
                      and more. IT consulting services are important because
                      they play a vital role in businesses by covering the
                      management, implementation, deployment, and maintenance of
                      an IT infrastructure. IT industry offers a sea of options,
                      from platforms, programming languages, methodologies,
                      technologies, tools, and more.
                    </p>
                  </div>
                </template>
              </accordion-item>
            </AccordionSlot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionSlot from "../Common/AccordionSlot";
import AccordionItem from "../Common/AccordionItem";

export default {
  name: "FaqAccordion",
  components: {
    AccordionSlot,
    AccordionItem,
  },
};
</script>