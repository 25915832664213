<template>
  <div class="features-area pt-100 pb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="single-features">
            <router-link to="/services-details">
              <img
                src="../../assets/images/features/features-1.png"
                alt="image"
              />
            </router-link>
            <h3>
              <router-link to="/services-details"
                >Strategic Planning</router-link
              >
            </h3>
            <p>
              Best Strategic planning dolor sit amet, consectetur adipiscing
              elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit
              nulla eget sodales dui pulvinar.
            </p>

            <div class="features-btn">
              <router-link to="/services-details" class="default-btn">
                Read More
                <i class="ri-arrow-right-line"></i>
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-features">
            <router-link to="/services-details">
              <img
                src="../../assets/images/features/features-2.png"
                alt="image"
              />
            </router-link>
            <h3>
              <router-link to="/services-details"
                >Research & Development</router-link
              >
            </h3>
            <p>
              Best Strategic planning dolor sit amet, consectetur adipiscing
              elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit
              nulla eget sodales dui pulvinar.
            </p>

            <div class="features-btn">
              <router-link to="/services-details" class="default-btn">
                Read More
                <i class="ri-arrow-right-line"></i>
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-features">
            <router-link to="/services-details">
              <img
                src="../../assets/images/features/features-3.png"
                alt="image"
              />
            </router-link>
            <h3>
              <router-link to="/services-details"
                >Design & Implimentation</router-link
              >
            </h3>
            <p>
              Best Strategic planning dolor sit amet, consectetur adipiscing
              elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit
              nulla eget sodales dui pulvinar.
            </p>

            <div class="features-btn">
              <router-link to="/services-details" class="default-btn">
                Read More
                <i class="ri-arrow-right-line"></i>
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-features">
            <router-link to="/services-details">
              <img
                src="../../assets/images/features/features-4.png"
                alt="image"
              />
            </router-link>
            <h3>
              <router-link to="/services-details"
                >Startup Applications</router-link
              >
            </h3>
            <p>
              Best Strategic planning dolor sit amet, consectetur adipiscing
              elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit
              nulla eget sodales dui pulvinar.
            </p>

            <div class="features-btn">
              <router-link to="/services-details" class="default-btn">
                Read More
                <i class="ri-arrow-right-line"></i>
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-features">
            <router-link to="/services-details">
              <img
                src="../../assets/images/features/features-5.png"
                alt="image"
              />
            </router-link>
            <h3>
              <router-link to="/services-details">SaaS Solutions</router-link>
            </h3>
            <p>
              Best Strategic planning dolor sit amet, consectetur adipiscing
              elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit
              nulla eget sodales dui pulvinar.
            </p>

            <div class="features-btn">
              <router-link to="/services-details" class="default-btn">
                Read More
                <i class="ri-arrow-right-line"></i>
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-features">
            <router-link to="/services-details">
              <img
                src="../../assets/images/features/features-6.png"
                alt="image"
              />
            </router-link>
            <h3>
              <router-link to="/services-details"
                >Software Development</router-link
              >
            </h3>
            <p>
              Best Strategic planning dolor sit amet, consectetur adipiscing
              elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit
              nulla eget sodales dui pulvinar.
            </p>

            <div class="features-btn">
              <router-link to="/services-details" class="default-btn">
                Read More
                <i class="ri-arrow-right-line"></i>
                <span></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="features-shape-1">
      <img src="../../assets/images/features/shape-1.png" alt="image" />
    </div>
    <div class="features-shape-2">
      <img src="../../assets/images/features/shape-2.png" alt="image" />
    </div>
    <div class="features-shape-3">
      <img src="../../assets/images/features/shape-3.png" alt="image" />
    </div>
    <div class="features-shape-4">
      <img src="../../assets/images/features/shape-4.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OurFeatures",
};
</script>