<template>
    <div class="blog-details-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-image">
                            <img src="../../assets/images/blog/blog-large-1.jpg" alt="image">
                        </div>

                        <div class="article-content">
                            <ul class="entry-meta">
                                <li>
                                    <i class="ri-user-line"></i>
                                    <a href="#">Admin</a>
                                </li>
                                <li>
                                    <i class="ri-time-line"></i>
                                    March 14, 2022
                                </li>
                                <li>
                                    <i class="ri-message-2-line"></i>
                                    (0) Comment
                                </li>
                            </ul>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>

                            <h3>Branding Involves Developing Strategy to Create a Point</h3>
                            <p>The IT industry offers a sea of options from platforms programming languages methodologies technologies tools and more. IT consulting services are important because they play a vital role in businesses by covering the management implementation deployment and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms programming languages methodologies technologies tools and more. Consulting services are important because.</p>

                            <ul class="features-list">
                                <li><i class="ri-check-line"></i> Nunc, mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis</li>
                                <li><i class="ri-check-line"></i> Most of the designer are very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis</li>
                                <li><i class="ri-check-line"></i> There are two thing is very important in Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis</li>
                                <li><i class="ri-check-line"></i> Web design and development very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet to all design and development.</li>
                            </ul>

                            <ul class="wp-block-gallery columns-3">
                                <li class="blocks-gallery-item">
                                    <figure>
                                        <img src="../../assets/images/blog/blog-7.jpg" alt="image">
                                    </figure>
                                </li>
                                <li class="blocks-gallery-item">
                                    <figure>
                                        <img src="../../assets/images/blog/blog-8.jpg" alt="image">
                                    </figure>
                                </li>
                                <li class="blocks-gallery-item">
                                    <figure>
                                        <img src="../../assets/images/blog/blog-9.jpg" alt="image">
                                    </figure>
                                </li>
                            </ul>

                            <p>The IT industry offers a sea of options from platforms programming languages methodologies technologies tools and more. IT consulting services are important because they play a vital role in businesses by covering the management implementation deployment and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms programming languages methodologies technologies tools and more. Consulting services are important because.</p>
                        </div>

                        <div class="article-author">
                            <img src="../../assets/images/clients/clients-4.png" alt="image">
                            <p>The IT industry offers a sea of options, from platforms, programming the languages, methodologies, technologies, tools, and more. IT consulting services are portant for because the sea of options, from platforms</p>
                            <span>Emma Kemeliy</span>
                        </div>

                        <div class="article-footer">
                            <div class="article-tags">
                                <a href="#">Agency</a>
                                <a href="#">Branding</a>
                            </div>

                            <div class="article-share">
                                <ul class="social">
                                    <li><span>Share On:</span></li>
                                    <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="ri-messenger-fill"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="article-tag">
                            <h4>Popular Tags</h4>

                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-blog">
                                        <div class="blog-image">
                                            <router-link to="/blog-details">
                                                <img src="../../assets/images/blog/blog-1.jpg" alt="image">
                                            </router-link>
                                        </div>
            
                                        <div class="blog-content">
                                            <ul class="entry-meta">
                                                <li class="tag">Branding</li>
                                                <li>
                                                    <i class="ri-time-line"></i>
                                                    March 14, 2022
                                                </li>
                                                <li>
                                                    <i class="ri-message-2-line"></i>
                                                    (0) Comment
                                                </li>
                                            </ul>
                                            <h3>
                                                <router-link to="/blog-details">
                                                    Branding Involves Developing a Strategy to Creating a Point of Differentiation.
                                                </router-link>
                                            </h3>
                                            <router-link to="/blog-details" class="blog-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-blog">
                                        <div class="blog-image">
                                            <router-link to="/blog-details">
                                                <img src="../../assets/images/blog/blog-2.jpg" alt="image">
                                            </router-link>
                                        </div>
            
                                        <div class="blog-content">
                                            <ul class="entry-meta">
                                                <li class="tag">Agency</li>
                                                <li>
                                                    <i class="ri-time-line"></i>
                                                    March 14, 2022
                                                </li>
                                                <li>
                                                    <i class="ri-message-2-line"></i>
                                                    (0) Comment
                                                </li>
                                            </ul>
                                            <h3>
                                                <router-link to="/blog-details">Design is a Plan or Specification For The Construction of an Object.</router-link>
                                            </h3>
                                            <router-link to="/blog-details" class="blog-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="article-comments">
                            <h4>2 Comments</h4>
                            
                            <div class="comments-list">
                                <img src="../../assets/images/clients/clients-1.png" alt="image">
                                <h5>Steven Smith</h5>
                                <span>April 24, 2022 at 10:59 AM</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#" class="reply-btn"><i class="ri-reply-fill"></i></a>
                            </div>

                            <div class="comments-list">
                                <img src="../../assets/images/clients/clients-3.png" alt="image">
                                <h5>James Anderson</h5>
                                <span>April 24, 2022 at 10:59 AM</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#" class="reply-btn"><i class="ri-reply-fill"></i></a>
                            </div>
                        </div>

                        <div class="article-leave-comment">
                            <h4>Leave a Comment</h4>
                            
                            <form>
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="text" class="form-control" placeholder="Name*">
                                        </div>

                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Email*">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Post A Comment <i class="ri-arrow-right-line"></i><span></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <BlogSidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlogSidebar from '../Common/BlogSidebar'

export default {
    name: 'BlogDetails',
    components: {
        BlogSidebar,
    }
}
</script>