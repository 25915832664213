<template>
  <div class="services-area bg-box-color pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Services We Offer</h2>
        <p>
          Best Strategic planning dolor sit amet consectetur adipiscing elit.
          Scelerisque amet odio velit auctor nam elit nulla eget sodales dui
          pulvina
        </p>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon">
              <i class="ri-quill-pen-line"></i>
            </div>
            <h3>
              <router-link to="/services-details"
                >Brand Identity Design</router-link
              >
            </h3>
            <p>
              Branding just like your personal identity makes you uniquely you
              your brand identity is the special sauce of your business that
              sets you apart from every other Tom Dick and Harry Inc.
            </p>
            <router-link to="/services-details" class="services-btn">
              Read More
              <i class="ri-arrow-right-line"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon bg-36CC72">
              <i class="ri-pie-chart-line"></i>
            </div>
            <h3>
              <router-link to="/services-details"
                >Digital Marketing</router-link
              >
            </h3>
            <p>
              Plob offering you the best digital marketing services to just like
              your personal identity makes you uniquely you, your brand identity
              is the special sauce of your to our customer.
            </p>
            <router-link to="/services-details" class="services-btn">
              Read More
              <i class="ri-arrow-right-line"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon bg-FF414B">
              <i class="ri-lightbulb-line"></i>
            </div>
            <h3>
              <router-link to="/services-details"
                >Design and Development</router-link
              >
            </h3>
            <p>
              Plob offering you the best digital marketing services to just like
              your personal identity makes you uniquely you, your brand identity
              is the special sauce of your to our customer.
            </p>
            <router-link to="/services-details" class="services-btn">
              Read More
              <i class="ri-arrow-right-line"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon bg-FF6D3D">
              <i class="ri-customer-service-2-line"></i>
            </div>
            <h3>
              <router-link to="/services-details"
                >IT Consulting Service</router-link
              >
            </h3>
            <p>
              Plob offering you the best digital marketing services to just like
              your personal identity makes you uniquely you, your brand identity
              is the special sauce of your to our customer.
            </p>
            <router-link to="/services-details" class="services-btn">
              Read More
              <i class="ri-arrow-right-line"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon bg-8932F8">
              <i class="ri-cloud-line"></i>
            </div>
            <h3>
              <router-link to="/services-details">Cloud Computing</router-link>
            </h3>
            <p>
              Plob offering you the best digital marketing services to just like
              your personal identity makes you uniquely you, your brand identity
              is the special sauce of your to our customer.
            </p>
            <router-link to="/services-details" class="services-btn">
              Read More
              <i class="ri-arrow-right-line"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon bg-FFCA40">
              <i class="ri-layout-row-line"></i>
            </div>
            <h3>
              <router-link to="/services-details"
                >Domain and Hosting</router-link
              >
            </h3>
            <p>
              Plob offering you the best digital marketing services to just like
              your personal identity makes you uniquely you, your brand identity
              is the special sauce of your to our customer.
            </p>
            <router-link to="/services-details" class="services-btn">
              Read More
              <i class="ri-arrow-right-line"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="services-shape-1">
      <img src="../../assets/images/services/shape-1.png" alt="image" />
    </div>
    <div class="services-shape-2">
      <img src="../../assets/images/services/shape-2.png" alt="image" />
    </div>
    <div class="services-shape-3">
      <img src="../../assets/images/services/shape-3.png" alt="image" />
    </div>
    <div class="services-shape-4">
      <img src="../../assets/images/services/shape-4.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
};
</script>