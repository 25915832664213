<template>
    <div class="projects-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-projects-box">
                        <div class="projects-image">
                            <router-link to="/case-study-details"><img src="../../assets/images/projects/projects-1.jpg" alt="image"></router-link>
                        </div>

                        <div class="projects-content">
                            <h3>
                                <router-link to="/case-study-details">Business Consulting</router-link>
                            </h3>
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
                            <router-link to="/case-study-details" class="projects-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-projects-box">
                        <div class="projects-image">
                            <router-link to="/case-study-details"><img src="../../assets/images/projects/projects-2.jpg" alt="image"></router-link>
                        </div>

                        <div class="projects-content">
                            <h3>
                                <router-link to="/case-study-details">Financial Consulting for Plob</router-link>
                            </h3>
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
                            <router-link to="/case-study-details" class="projects-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-projects-box">
                        <div class="projects-image">
                            <router-link to="/case-study-details"><img src="../../assets/images/projects/projects-3.jpg" alt="image"></router-link>
                        </div>

                        <div class="projects-content">
                            <h3>
                                <router-link to="/case-study-details">Social Media Marketing</router-link>
                            </h3>
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
                            <router-link to="/case-study-details" class="projects-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-projects-box">
                        <div class="projects-image">
                            <router-link to="/case-study-details"><img src="../../assets/images/projects/projects-4.jpg" alt="image"></router-link>
                        </div>

                        <div class="projects-content">
                            <h3>
                                <router-link to="/case-study-details">SEO Optimization</router-link>
                            </h3>
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
                            <router-link to="/case-study-details" class="projects-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-projects-box">
                        <div class="projects-image">
                            <router-link to="/case-study-details"><img src="../../assets/images/projects/projects-5.jpg" alt="image"></router-link>
                        </div>

                        <div class="projects-content">
                            <h3>
                                <router-link to="/case-study-details">Advanced Analytics</router-link>
                            </h3>
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
                            <router-link to="/case-study-details" class="projects-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-projects-box">
                        <div class="projects-image">
                            <router-link to="/case-study-details"><img src="../../assets/images/projects/projects-6.jpg" alt="image"></router-link>
                        </div>

                        <div class="projects-content">
                            <h3>
                                <router-link to="/case-study-details">Email Marketing</router-link>
                            </h3>
                            <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
                            <router-link to="/case-study-details" class="projects-btn">Read More <i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="#" class="prev page-numbers"><i class="ri-arrow-left-line"></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="#" class="page-numbers">2</a>
                        <a href="#" class="page-numbers">3</a>
                        <a href="#" class="page-numbers">4</a>
                        <a href="#" class="next page-numbers"><i class="ri-arrow-right-line"></i></a>
                    </div>
                </div>            
            </div>
        </div>

        <div class="projects-shape-1">
            <img src="../../assets/images/projects/shape-1.png" alt="image">
        </div>
        <div class="projects-shape-2">
            <img src="../../assets/images/projects/shape-2.png" alt="image">
        </div>
        <div class="projects-shape-3">
            <img src="../../assets/images/projects/shape-3.png" alt="image">
        </div>
        <div class="projects-shape-4">
            <img src="../../assets/images/projects/shape-4.png" alt="image">
        </div>
        <div class="projects-shape-5">
            <img src="../../assets/images/projects/shape-5.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CaseStudy'
}
</script>