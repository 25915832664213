<template>
    <div class="cases-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Our Realizations / Recent Cases</h2>
                <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit auctor nam elit nulla eget sodales dui pulvina</p>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="single-cases">
                        <div class="cases-image">
                            <router-link to="/case-study-details">
                                <img src="../../assets/images/cases-study/cases-1.jpg" alt="image">
                            </router-link>
                        </div>
                        
                        <div class="cases-content">
                            <div class="tag-1">Branding</div>
                            <div class="tag-2">Development</div>

                            <h3>
                                <router-link to="/case-study-details">Product Branding and Web Development</router-link>
                            </h3>
                            <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets you apart from every other Tom, Dick, and Harry, Inc. Branding just like your personal identity makes you uniquely.</p>
                        </div>
                    </div>

                    <div class="single-cases">
                        <div class="cases-image">
                            <router-link to="/case-study-details">
                                <img src="../../assets/images/cases-study/cases-2.jpg" alt="image">
                            </router-link>
                        </div>
                        
                        <div class="cases-content">
                            <div class="tag-1">Business</div>

                            <h3>
                                <router-link to="/case-study-details">Brand Identity and Mockup</router-link>
                            </h3>
                            <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets you apart from every other Tom.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="single-cases">
                        <div class="cases-image">
                            <router-link to="/case-study-details">
                                <img src="../../assets/images/cases-study/cases-3.jpg" alt="image">
                            </router-link>
                        </div>
                        
                        <div class="cases-content">
                            <div class="tag-1">App Design</div>

                            <h3>
                                <router-link to="/case-study-details">Banking Mobile iOS App Design</router-link>
                            </h3>
                            <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets you apart from every other Tom, Dick, and Harry, Inc. Branding just like your personal identity makes you uniquely.</p>
                        </div>
                    </div>

                    <div class="single-cases">
                        <div class="cases-image">
                            <router-link to="/case-study-details">
                                <img src="../../assets/images/cases-study/cases-4.jpg" alt="image">
                            </router-link>
                        </div>
                        
                        <div class="cases-content">
                            <div class="tag-1">App Design</div>

                            <h3>
                                <router-link to="/case-study-details">Plob Website Design and Development</router-link>
                            </h3>
                            <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets.</p>
                        </div>
                    </div>
                </div>

                <div class="cases-view-all-btn">
                    <router-link to="/case-study-one" class="default-btn">
                        View All Projects 
                        <i class="ri-briefcase-line"></i>
                        <span></span>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="cases-shape-1">
            <img src="../../assets/images/cases-study/shape-1.png" alt="image">
        </div>
        <div class="cases-shape-2">
            <img src="../../assets/images/cases-study/shape-2.png" alt="image">
        </div>
        <div class="cases-shape-3">
            <img src="../../assets/images/cases-study/shape-3.png" alt="image">
        </div>
        <div class="cases-shape-4">
            <img src="../../assets/images/cases-study/shape-4.png" alt="image">
        </div>
        <div class="cases-shape-5">
            <img src="../../assets/images/cases-study/shape-5.png" alt="image">
        </div>
        <div class="cases-shape-6">
            <img src="../../assets/images/cases-study/shape-4.png" alt="image">
        </div>
        <div class="cases-shape-7">
            <img src="../../assets/images/cases-study/shape-5.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecentCases'
}
</script>