<template>
    <div class="process-area">
        <div class="container">
            <div class="process-inner-box">
                <div class="row">
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-process">
                            <i class="ri-hard-drive-line"></i>
                            <p>Research</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="single-process bg-36CC72">
                            <i class="ri-pie-chart-line"></i>
                            <p>Analyze</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="single-process bg-FF414B">
                            <i class="ri-quill-pen-line"></i>
                            <p>Design</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="single-process bg-FF6D3D">
                            <i class="ri-focus-line"></i>
                            <p>Testing</p>
                        </div>
                    </div>
                </div>

                <div class="process-bar-shape">
                    <img src="../../assets/images/process-bar.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurProcess'
}
</script>