<template>
    <div class="projects-area ptb-100">
        <div class="container-fluid">
            <div class="section-title">
                <h2>Recent Case Studies</h2>
                <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit auctor. nam elit nulla eget sodales dui pulvina</p>
            </div>

            <div class="projects-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-projects">
                            <div class="projects-image">
                                <router-link to="/case-study-details">
                                    <img :src="slide.image" alt="image">
                                </router-link>
                            </div>

                            <div class="projects-content">
                                <h3>
                                    <router-link to="/case-study-details">
                                        {{slide.title}}
                                    </router-link>
                                </h3>
                                <p>{{slide.description}}</p>
                                <router-link to="/case-study-details" class="projects-btn">
                                    Read More 
                                    <i class="ri-arrow-right-line"></i>
                                </router-link>
                            </div>
                        </div>
                    </slide>
                    
                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>

        <div class="projects-shape-1">
            <img src="../../assets/images/projects/shape-1.png" alt="image">
        </div>
        <div class="projects-shape-2">
            <img src="../../assets/images/projects/shape-2.png" alt="image">
        </div>
        <div class="projects-shape-3">
            <img src="../../assets/images/projects/shape-3.png" alt="image">
        </div>
        <div class="projects-shape-4">
            <img src="../../assets/images/projects/shape-4.png" alt="image">
        </div>
        <div class="projects-shape-5">
            <img src="../../assets/images/projects/shape-5.png" alt="image">
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'CaseStudies',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/projects/projects-1.jpg'),
                title: 'Business Consulting',
                description: 'Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.',
            },
            {
                id: 2,
                image: require('../../assets/images/projects/projects-2.jpg'),
                title: 'Financial Consulting for Plob',
                description: 'Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.',
            },
            {
                id: 3,
                image: require('../../assets/images/projects/projects-3.jpg'),
                title: 'Social Media Marketing',
                description: 'Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.',
            },
            {
                id: 4,
                image: require('../../assets/images/projects/projects-4.jpg'),
                title: 'SEO Optimization',
                description: 'Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.',
            },
            {
                id: 5,
                image: require('../../assets/images/projects/projects-5.jpg'),
                title: 'Advanced Analytics',
                description: 'Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
    }),
})
</script>