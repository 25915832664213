<template>
    <div class="fun-facts-area">
        <div class="container">
            <div class="fun-facts-box">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="single-fun-fact">
                            <div class="icon">
                                <i class="ri-user-line"></i>
                            </div>
                            <h3>3230+</h3>
                            <p>Total Cases</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="single-fun-fact">
                            <div class="icon">
                                <i class="ri-briefcase-line"></i>
                            </div>
                            <h3>3026+</h3>
                            <p>Case Solved</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="single-fun-fact">
                            <div class="icon">
                                <i class="ri-trophy-line"></i>
                            </div>
                            <h3>320+</h3>
                            <p>Winning Awards</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="single-fun-fact">
                            <div class="icon">
                                <i class="ri-flag-line"></i>
                            </div>
                            <h3>102+</h3>
                            <p>Country Over</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fun-facts-shape-1">
            <img src="../../assets/images/fun-facts/shape-1.png" alt="image">
        </div>
        <div class="fun-facts-shape-2">
            <img src="../../assets/images/fun-facts/shape-2.png" alt="image">
        </div>
        <div class="fun-facts-shape-3">
            <img src="../../assets/images/fun-facts/shape-3.png" alt="image">
        </div>
        <div class="fun-facts-shape-4">
            <img src="../../assets/images/fun-facts/shape-4.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'FunFacts'
}
</script>